import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { DividedSection, Title, Text } from "gatsby-theme-material-foundry"
import { Container, makeStyles, Box, Hidden } from "@material-ui/core"
import { useTheme } from "@material-ui/styles"
import SEO from "../components/SEO/seo"
import { StudentSvg } from "../components/svgs"
import FilterButton from "../components/filter-button"
import { StudEnt, Framo, BKK } from "../components/finance-page-content"

const useStyles = makeStyles(theme => ({
  subtitle: {
    fontWeight: 600,
  },
  ptext: {
    fontSize: "1.5em",
  },
  buttonline: {
    height: "40px",
    marginTop: "-40px",
  },
  hero: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "150px",
      paddingBottom: "50px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "250px",
      paddingBottom: "100px",
    },
  },
  svg: {
    width: "200px",
  },
}))

function StudEntPage({ data }) {
  const [activePage, setActivePage] = React.useState("stud-ent")
  const playwell = data.playwell.childImageSharp.fluid
  const altreal = data.altreal.childImageSharp.fluid
  const dramax = data.dramax.childImageSharp.fluid
  const amiroh = data.amiroh.childImageSharp.fluid
  const betterplayer = data.betterplayer.childImageSharp.fluid
  const svemko = data.svemko.childImageSharp.fluid
  const classes = useStyles()
  const theme = useTheme()
  return (
    <Layout dark>
      <SEO
        postTitle="Stud-ENT"
        slug="stud-ENT"
        postDescription="Søk forskningsrådets Stud-ENT og få tildelt 1 million kroner til din studentbedrift."
      />
      <Container maxWidth="md">
        <DividedSection className={classes.hero}>
          <Box>
            <Title variant="h2" primary>
              Finansiering
            </Title>
            <Text black variant="h6" className={classes.subtitle}>
            HVL Skape tilbyr finansiell støtte til studenter som ønsker å arbeide med egne forretningsidéer. I tillegg mobiliserer vi til, bistår i og administrerer søknader til Forskningsrådets Stud-ENT-ordning.
            </Text>
          </Box>
          <Hidden xsDown>
            <Container align="center">
              <StudentSvg
                fill1={theme.palette.black.main}
                fill2={theme.palette.primary.main}
                fill3="#e2e2ec"
                fill4={theme.palette.black.main}
                className={classes.svg}
              />
            </Container>
          </Hidden>
        </DividedSection>
        <Box className={classes.buttonline}>
          <Container maxWidth="md" align="center">
            <FilterButton
              filterName="stud-ent"
              setFilter={setActivePage}
              filter={activePage}
            />
            <FilterButton
              filterName="Framo"
              setFilter={setActivePage}
              filter={activePage}
            />
           
          </Container>
        </Box>
      </Container>
      <Box minHeight="50vh" mt="30px">
        {activePage === "stud-ent" && (
          <StudEnt
            altreal={altreal}
            playwell={playwell}
            betterplayer={betterplayer}
            amiroh={amiroh}
            dramax={dramax}
            svemko={svemko}
          />
        )}
        {activePage === "Framo" && <Framo file={data.framoMal.publicURL}/>}
        {activePage === "BKK Bidraget" && <BKK />}
      </Box>
    </Layout>
  )
}

export const StudentPageQuery = graphql`
  query studentPageQuery {
    framoMal: file(relativePath: { eq: "Framostipendet-mal.docx" }){
      publicURL
    }
    playwell:  file(relativePath: { eq: "playwell.jpg" }){
      childImageSharp {
        fluid(maxHeight: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    amiroh:  file(relativePath: { eq: "amiroh.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dramax:  file(relativePath: { eq: "dramax.jpg" }){
      childImageSharp {
        fluid(maxHeight: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    betterplayer: file(name: { eq: "betterplayer" }) {
      childImageSharp {
        fluid(maxHeight: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    altreal: file(name: { eq: "hanamichelle" }) {
      childImageSharp {
        fluid(maxHeight: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    svemko: file(name: { eq: "svemko" }) {
      childImageSharp {
        fluid(maxHeight: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StudEntPage
