import React from "react"
import { Box, Grid, Divider, Container, Avatar, Card } from "@material-ui/core"
import { Link, navigate } from "gatsby"
import { Button, Title, Text, Badge } from "gatsby-theme-material-foundry"
import NextIcon from "@material-ui/icons/NavigateNextOutlined"
import { makeStyles } from "@material-ui/core/styles"
import Img from "gatsby-image"
import OverlayImage, { NewsImage } from "./OverlayImage"
import Envelope from "@material-ui/icons/Email"
import EventList from "./event-list"

export const useStyles = makeStyles(theme => ({
  email: {
    fontSize: "0.9em",
    marginLeft: "5px",
    color: theme.palette.secondary.dark,
    textDecoration: "none",
  },
  imgCard: {
    borderRadius: "6px !important",
    boxShadow:
      "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    maxWidth: "100%",
    height: "auto",
  },
  projImgCard: {
    borderRadius: "6px !important",
    boxShadow:
      "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    maxWidth: "100%",
    height: "200px",
  },
  imgContainer: {
    minHeight: "150px",
  },
  cardContainer: {
    overflow: "auto",
    height: "240px",
  },
  readmore: {
    fontWeight: 400,
    marginLeft: "10px",
    textDecoration: "none",
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.secondary.dark,
      textDecoration: "underline",
    },
  },
  cards: {
    backgroundColor: theme.palette.black.main,
  },
  hovered: {
    backgroundColor: theme.palette.black.light,
  },
  eventCard: {
    marginTop: "10px",
    borderRadius: "3px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.1)",
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  aboutContainer: {
    color: "white",
  },
  greenSection: {},

  avatar: {
    margin: 10,
  },
  personTitle: {
    color: "white",
    padding: 0,
    fontSize: "0.8em",
    marginLeft: "5px",
  },
  avatarname: {
    margin: "0 5px",
    padding: "0",
    fontSize: "1em",
  },
  bigAvatar: {
    width: 100,
    height: 100,
    marginRight: "24px",
  },
  title: {
    margin: "0",
    padding: "0",
  },
  subtitle: {
    margin: "0",
    padding: "0",
    fontWeight: "400",
    color: "white",
  },
}))

function EventCard({
  title,
  image,
  backupImage,
  description,
  link,
  date,
  tags,
  campus,
  adresse,
  multiDate
}) {
  const classes = useStyles()
  return (
    <Grid item xs={12} sm={4}>
      <Box
        p={2}
        onClick={() => navigate(link)}
        className={classes.eventCard}
        role="button"
      >
        <Box mb={4}>
          <OverlayImage
            backupImage={backupImage}
            image={image}
            text={description}
            date={date}
            multiDate={multiDate}
          />
        </Box>
        <Badge color="black">{campus}</Badge>
        {tags.map(tag => (
          <Badge color="black" key={tag} simple>
            {tag}
          </Badge>
        ))}
        <Title black variant="h6" className={classes.title}>
          {title}
        </Title>
        <EventList horizontal location={adresse} />
      </Box>
    </Grid>
  )
}

export function NewsCard({ title, image, description, link }) {
  const classes = useStyles()
  return (
    <Grid item xs={6}>
      <Box p={2}>
        <Link to={link} className={classes.readmore}>
          <NewsImage image={`${image}&sz=w400`} text={title} />
        </Link>
      </Box>
    </Grid>
  )
}

export function ProjectCard({ title, image, description, link }) {
  const classes = useStyles()
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box p={2}>
        <Box className={classes.imgContainer}>
          <Img fluid={image} alt={title} className={classes.projImgCard} />
        </Box>
        <Box className={classes.cardContainer}>
          <Title variant="h5" primary>
            {title}
          </Title>
          <Text paragraph variant="body2">
            {description}
          </Text>
        </Box>
        <Button fullWidth align="center" color="black" to={link}>
          Les mer
          <NextIcon />
        </Button>
      </Box>
    </Grid>
  )
}

export function AvatarCard({ name, role, img, imageClasses, email, link }) {
  const classes = useStyles()
  return (
    <Box display="flex" justifyContent="center" flexDirection="column" p={2}>
      <Box align="center" pb={2}>
        <Avatar
          src={`${img}&sz=w200`}
          width="100px"
          alt={name}
          className={imageClasses}
        />
      </Box>
      <Box>
        <Title
          component="a"
          href={link}
          black
          variant="h5"
          align="center"
          style={{ fontSize: "1.2em", marginBottom: 0 }}
        >
          {name}
        </Title>
        <Text variant="subtitle2" black align="center" gutterBottom>
          {role}
        </Text>
        <Divider />
        <Box p={1} align="center" display="flex" justifyContent="center">
          {email && (
            <>
              <Envelope color="secondary" fontSize="small" />{" "}
              <Text
                component="a"
                className={classes.email}
                href={"mailto:" + email}
              >
                {email}
              </Text>
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export function SingleCampus({
  name,
  hovered,
  image,
  contactPerson,
  onHover,
  address,
}) {
  const classes = useStyles()
  return (
    <Grid Item xs={12} md={6} lg={4}>
      <Card
        className={hovered === name ? classes.hovered : classes.cards}
        elevation={hovered === name ? 3 : 0}
        onMouseEnter={() => onHover(name)}
        onMouseLeave={() => onHover("")}
        role="button"
      >
        <Box p={2}>
          <Img fluid={image} className={classes.imgCard} />
        </Box>

        <Box m={2}>
          <Title
            variant="h5"
            primary
            component="a"
            className={classes.title}
            href={`https://www.hvl.no/studentliv/studentliv-${
              name === "Førde" ? "forde" : name
            }/campus-${name === "Førde" ? "forde" : name}/`}
          >
            {name}
          </Title>
          <Text variant="subtitle1" className={classes.subtitle}>
            {address}
          </Text>
          <Box display="flex" mt={2}>
            <Avatar src={contactPerson.avatar} />
            <Box>
              <Title
                secondary
                className={classes.avatarname}
                component="a"
                href={contactPerson.hvlprofilside}
              >
                {contactPerson.fornavn} {contactPerson.etternavn}
              </Title>
              <Text className={classes.personTitle}>
                {contactPerson.stilling}
              </Text>
            </Box>
          </Box>
        </Box>
      </Card>
    </Grid>
  )
}
/** <CampusDialog /> */

export function CampusCards({ data, hovered, onHover, onClick }) {
  return (
    <Container maxWidth="md" align="left">
      <Grid container>
        <SingleCampus
          name="sogndal"
          contactPerson={data.user.nodes[1]}
          hovered={hovered}
          onHover={onHover}
          onClick={onClick}
          image={data.sogndal.childImageSharp.fluid}
        />
        <SingleCampus
          name="førde"
          contactPerson={data.user.nodes[2]}
          hovered={hovered}
          onHover={onHover}
          onClick={onClick}
          image={data.stord.childImageSharp.fluid}
        />
        <SingleCampus
          name="bergen"
          contactPerson={data.user.nodes[0]}
          hovered={hovered}
          onHover={onHover}
          onClick={onClick}
          image={data.kronstadSmall.childImageSharp.fluid}
        />

        <SingleCampus
          name="stord"
          contactPerson={data.user.nodes[2]}
          hovered={hovered}
          onHover={onHover}
          onClick={onClick}
          image={data.stord.childImageSharp.fluid}
        />
        <SingleCampus
          name="haugesund"
          contactPerson={data.user.nodes[2]}
          hovered={hovered}
          onHover={onHover}
          onClick={onClick}
          image={data.haugesund.childImageSharp.fluid}
        />
      </Grid>
    </Container>
  )
}

export default EventCard
