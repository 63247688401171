import React from "react"
import { Button } from "gatsby-theme-material-foundry"
import {Container, Grid} from "@material-ui/core"

const FilterButton = ({ filterName, filter, setFilter, data }) => (
  <Button
    onClick={() => setFilter(filterName)}
    color="black"
    size="md"
    round
    simple={filter === filterName ? false : true}
  >
    {filterName}
  </Button>
)

export default FilterButton


export function FilterButtonList({filterNames, setEvents, setFilter, data, filter, type}){
  return(
    <Container maxWidth="md" align="center">
    <Grid container>
      {filterNames.map(f => 
      <Grid item xs={12} sm={4} lg={2}>
  <Button
    onClick={() => {
      setEvents(
        type === "events" ? data.allArrangement.nodes.filter(e =>
          f === "" ? e : e.campus === f
        ) :  data.allNyheter.nodes.filter(e =>
          f === "" ? e : e.campus === f
        )
      )
      setFilter({ campus: f })
    }}
    color="black"
    size="md"
    round
    simple={filter.campus === f ? false : true}
  >
    {f === "" ? "alle" : f}
  </Button>
    </Grid>)}
    </Grid>
  </Container>
  )
}