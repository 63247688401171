import React from "react"
import BackgroundImage from "gatsby-background-image"
import { Title, Text } from "gatsby-theme-material-foundry"
import { makeStyles, Box } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  outerBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      height: "300px",
    },
    [theme.breakpoints.up("md")]: {
      height: "150px",
    },
  },
  innerBox: {
    backgroundColor: "rgba(255,255,255, 1)",
    width: "120px",
    height: "120px",
    borderRadius: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    margin: 0,
    padding: 0,
    fontSize: "36px"
  },
  subText: {
    fontWeight: 400,
    margin: 0,
    padding: 0,
  },
}))

export default function EventImage({ backupImage, image, text, date, multiDate }) {
  const classes = useStyles()
  if(image) {
    return (
      <div
        style={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          boxShadow:
            "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Box className={classes.outerBox}>
          <Box className={classes.innerBox}>
            <Title primary variant="h2" component="h6" className={classes.text}>
              {multiDate ? date.split(" ")[0] : date.split(" ")[1]}
            </Title>
            <Text secondary className={classes.subText}>
            {multiDate ? date.split(" ")[1] : date.split(" ")[2]}
            </Text>
          </Box>
        </Box>
      </div>
    )
  } else {
  return (
    <BackgroundImage
      alt={`Picture of ${text}`}
      fluid={backupImage}
      style={{
        boxShadow:
          "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Box className={classes.outerBox}>
        <Box className={classes.innerBox}>
          <Title primary variant="h2" component="h6" className={classes.text}>
          {multiDate ? date.split(" ")[0] : date.split(" ")[1]}
          </Title>
          <Text secondary className={classes.subText}>
          {multiDate ? date.split(" ")[1] : date.split(" ")[2]}
          </Text>
        </Box>
      </Box>
    </BackgroundImage>
  )}
}

export function NewsImage({ image, text }) {
  return (
    <>
      <img
        alt={`${text}`}
        src={image}
        style={{
          width: "100%",
          maxHeight: "150px",
          objectFit: "cover",
          objectPosition: "100% 50%",
          boxShadow:
            "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
        }}
      />

      <Box>
        <Title black variant="h6" component="h6">
          {text}
        </Title>
      </Box>
    </>
  )
}
