import React from "react"
import { Button, Badge, Text } from "gatsby-theme-material-foundry"
import {
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Box,
  Grid,
} from "@material-ui/core"
import LocationIcon from "@material-ui/icons/LocationOn"
import CalendarIcon from "@material-ui/icons/CalendarToday"
import TimeIcon from "@material-ui/icons/AccessTime"
import FoodIcon from "@material-ui/icons/Fastfood"
import GroupIcon from "@material-ui/icons/Group"

function GenerateList({
  horizontal,
  date,
  time,
  location,
  food,
  maxAttendees,
  register,
  facebook
}) {
  if (horizontal) {
    return (
      <Grid container flexDirection="row">
        {date && (
          <>
            <Grid item>
              <CalendarIcon fontSize="small" />
            </Grid>
            <Grid item>
              <Box ml={1} mr={2}>
                <Text black>{date}</Text>
              </Box>
            </Grid>
          </>
        )}
        {time && (
          <>
            <Grid item>
              <TimeIcon fontSize="small" />
            </Grid>
            <Grid item>
              <Box ml={1} mr={2}>
                {time}
              </Box>
            </Grid>
          </>
        )}
        {location && (
          <>
            <Grid item>
              <LocationIcon fontSize="small" />
            </Grid>
            <Grid item>
              <Box ml={1} mr={2}>
                {location}
              </Box>
            </Grid>
          </>
        )}
        {food && (
          <>
            <Grid item>
              <FoodIcon fontSize="small" />
            </Grid>
            <Grid item>
              {" "}
              <Box ml={1} mr={2}>
                {food}
              </Box>
            </Grid>
          </>
        )}
        {maxAttendees && (
          <>
            <Grid item>
              <GroupIcon fontSize="small" />
            </Grid>
            <Grid item>
              <Box ml={1} mr={2}>
                {maxAttendees}
              </Box>
            </Grid>
          </>
        )}
           {register && (
          <Button color="primary" to={register} fullWidth>
            Register
          </Button>
        )}
          {facebook && (
          <Button color="secondary" simple to={facebook} fullWidth>
            Facebook Event
          </Button>
        )}
      </Grid>
    )
  } else {
    return (
      <List>
        {date && (
          <ListItem>
            <ListItemIcon>
              <CalendarIcon />
            </ListItemIcon>
            <ListItemText primary={date} />
          </ListItem>
        )}
        {time && (
          <ListItem>
            <ListItemIcon>
              <TimeIcon />
            </ListItemIcon>
            <ListItemText primary={time} />
          </ListItem>
        )}

        {location && (
          <ListItem>
            <ListItemIcon>
              <LocationIcon />
            </ListItemIcon>
            <ListItemText primary={location} />
          </ListItem>
        )}
        {food && (
          <ListItem>
            <ListItemIcon>
              <FoodIcon />
            </ListItemIcon>
            <ListItemText primary={food} />
          </ListItem>
        )}
        {maxAttendees && (
          <ListItem>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary={maxAttendees} />
          </ListItem>
        )}
          {register && (
          <Button color="primary" to={register} fullWidth>
            Registrer
          </Button>
        )}
          {facebook && (
          <Button color="facebook" simple to={facebook} fullWidth>
            Se på facebook
          </Button>
        )}
      </List>
    )
  }
}

export function GenerateTags({ tags, color }) {
  return tags.map(tag => (
    <Badge color={color} key={tag} simple>
      {tag}
    </Badge>
  ))
}

export default GenerateList
