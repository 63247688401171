import React from "react"
import { Container, Box, Grid, makeStyles } from "@material-ui/core/"
import {
  Title,
  Text,
  DividedSection,
  Button,
} from "gatsby-theme-material-foundry"
import { ProjectCard } from "./event-cards"
import Dialog from "./form-dialog"
import InfoIcon from "@material-ui/icons/Info"

const useStyles = makeStyles(theme => ({
  subtitle: {
    fontWeight: 600,
  },
  ptext: {
    fontSize: "1.5em",
  },
}))

export function Framo({ file }) {
  const classes = useStyles()

  return (
    <Container maxWidth="md">
      <Title variant="h3" black>
        Framostipendet
      </Title>
      <Text black variant="h6" className={classes.subtitle}>
        Få inntill 10.000 kroner til aktiviteter knyttet til din studentbedrift
      </Text>
      <Text paragraph className={classes.ptext}>
        Framostipendet er et startskudd for studentgründere med gode ideer.
        Stipendet er på 10.000 kroner og deles ut til enkeltstudenter eller
        studentteam. Vi deler ut 4 stipender i året.
      </Text>
      <Text paragraph className={classes.ptext}>
        Last ned søknadsmal og send søknad til{" "}
        <a href="mailto:skape@hvl.no">skape@hvl.no</a>.
        Vi vurderer søknader fortløpende.
      </Text>
      <Button color="primary" to={file}>
        Last ned søknadsmal
      </Button>
    </Container>
  )
}

export function BKK() {
  const classes = useStyles()

  return (
    <Container maxWidth="md">
      <Title variant="h3" black>
        BKK Bidraget
      </Title>
      <Text black variant="h6" className={classes.subtitle}>
        Stipend fra BKK
      </Text>
      <Text paragraph className={classes.ptext}>
        Mer info kommer etter hvert
      </Text>
    </Container>
  )
}

export function StudEnt({ playwell, altreal, betterplayer, dramax, amiroh, svemko }) {
  const classes = useStyles()
  return (
    <>
      <Container maxWidth="md">
        <Title variant="h3" black>
          Stud-ENT
        </Title>
        <Text black variant="h6" className={classes.subtitle}>
          Søk om 1 million kroner til din studentbedrift fra forskningsrådets
          Stud-ENT ordning.
        </Text>
        <Text paragraph className={classes.ptext}>
          Stud-ENT» er Norges Forskningsråds stipendordning for å fremme
          studententreprenørskap og motivere studenter til å bli gründere.
          Mohnsenteret bistår og veileder Stud-ENT-kandidater gjennom
          søknadsprosessen. Vi hjelper deg også med å finne et passende fagmiljø
          og en faglig mentor. Målgruppen for Stud-ENT-stipendene er
          masterstudenter i siste studiefase og nyutdannede med mastergrad som
          har en idé med forretningspotensiale som bygger på ny kunnskap. Hvert
          enkelt studentprosjekt kan få finansiering i inntil 12 måneder og
          opptil 1 million kroner per prosjekt. Studentene har selv ansvaret for
          utforming av søknaden, men en forutsetning for prosjektene er at det
          etableres et prosjektteam med ansvarlig prosjektleder og at
          prosjektet er forankret i et FoU-miljø. Stipendmidlene kan gå til
          lønnskostnader, innkjøp av tjenester, forskning, teknisk og
          administrativ bistand, materiell, mm.
        </Text>
      </Container>

      <div
        style={{
          background:
            "linear-gradient(rgba(206,241,207,.92) 0,rgba(206,241,207,.92) 100%),linear-gradient(#96de99 0,#96de99 100%)",
          color: "#004357",
        }}
      >
        <Container maxWidth="md">
          <Box pt={4} pb={8}>
            <Title variant="h3" align="center" black>
              Tidligere million-tildelinger
            </Title>
            <Text variant="h5" align="center">
              HVL Skape har tidligere vært involert i flertallet
              vellykkede Stud-ENT-søknader hvor studentgruppene fikk tildelt 1
              million kroner hver.
            </Text>
            <Grid container>
            <ProjectCard
                title="Svemko"
                description="Mats Kleppe har nyleg fått 1 million kroner frå Forskningsrådet for å vidareutvikle eit produkt han har utvikla som student ved HVL."
                link="https://www.hvl.no/aktuelt/1-million-til-studentprosjekt/"
                image={svemko}
              />
              <ProjectCard
                title="Altreal"
                description="Svipp•in er en applikasjon utviklet av AltReal AS, hvor formålet er å øke oppmøte på ulike lokasjoner.
Svipp•in motiverer mennesker til å møte opp på «rett sted til rett tid» for å generere poeng som senere kan veksles inn mot reelle goder."
                link="https://www.visinnovasjon.no/2018/01/winner-studentide-2018/"
                image={altreal}
              />
              <ProjectCard
                title="Playwell"
                description="HVL-studentane Constandino Leiva og Sjur Hovi har fått ein million kroner frå Norges forskningsråd til å realisere studentbedrifta PlayWell. Forretningsideen deira er å bruke dataspel til å styrke samhald og integrering blant ungdom."
                link="https://www.hvl.no/aktuelt/studentbedrift-far-millionstotte/"
                image={playwell}
              />
              <ProjectCard
                title="DramaX"
                description="Kompani DramaX; Teater-i-undervisning (TIU) AS, vant Forskningsrådets STUD-ENT stipend i 2017. Deres forretningsidé var et eget pedagogisk opplegg rettet mot barn i barnehagealder og på barneskoler hvor de nyttiggjør såkalte TIU-metoder. Her tar de opp dagsaktuelle og mellommenneskelige temaer som illustreres gjennom en interaktiv og inkluderende teaterform. Teamet bak Kompani DramaX er Marte Rosenvinge (daglig leder), Trine Ask og Ingelin Mjåseth Hilland. Alle er utdannet barnehagelærere. "
                link="https://www.hvl.no/aktuelt/involverer-bachelorstudentar-i-produktutvikling/"
                image={dramax}
              />
              <ProjectCard
                title="Amiroh"
                description="Amiroh bestående av Tomas Roaldsnes, Eirik Sverd og Maria Sygård Steen mottok STUD-ENT stipendet våren 2017. Deres forretningsidé gikk ut på å utvikle og produsere et smart sminkespeil med innebygd smart lysstyring og mulighet til å være på sosiale medier samtidig som du sminket deg."
                link="https://www.hvl.no/aktuelt/millionar-til-studententreprenorskap/"
                image={amiroh}
              />
              <ProjectCard
                title="Better Player"
                description="Mikkel Frodahl er akkurat uteksaminert frå Høgskulen i Sogn og Fjordane og lagde dataprogrammet «Better Player», som ein del av masteroppgåva si i idrettsvitskap."
                link="https://www.nrk.no/sognogfjordane/student-far-million-til-fotballteknologi-1.13006491"
                image={betterplayer}
              />
            </Grid>
          </Box>
        </Container>
      </div>
      <DividedSection>
        <Container maxWidth="sm">
          <Box pt={4} pb={8} align="center">
            <Title variant="h3" align="center" black>
              Ønsker du også søke Stud-ENT?
            </Title>
            <Box my={2}>
              <Text>
                {" "}
                <InfoIcon color="primary" style={{ paddingTop: "10px" }} />{" "}
                <a href="https://www.forskningsradet.no/utlysninger/2020/studententreprenorskap/">
                  {" "}
                  Les mer og hent maler på Forskningsrådets sine sider.
                </a>
              </Text>
            </Box>
            <Text variant="h6" align="center" black gutterBottom>
              Vi hjelper med søknadsprosessen og arrangerer info-møter for folk
              som er interessert i å søke Stud-ENT.
            </Text>
            <Dialog />
          </Box>
        </Container>
      </DividedSection>
    </>
  )
}
